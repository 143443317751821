<template>
  <BasePage>
    <div class="content_wrap">
      <Banner />
      <Form />
      <!-- Money -->
      <Content>
        <template #content>
          <p>
            Do Survey, finds the best survey companies and other money-making
            opportunities and makes it easy for you to sign up to one or all of
            them. It’s free to use – at no point will you pay a single penny.
          </p>
          <p>
            Do Survey is a free service community, please do not malicious to
            provide suggestions to get rewards.
          </p>
        </template>
      </Content>

      <!-- JOIN US -->
      <Content
        :img="team_icon"
        type="left"
        title="RREQUIREMENTS FOR JOINING OUR TEAM"
        title2="What Types of Home Based Market Research Jobs are Available?"
      >
        <template #content>
          <p>
            There are a number of Market Research jobs that can be carried out
            from home as long as you have a computer and access to the internet.
          </p>
          <p>
            One of the most popular of these jobs is <span
              style="font-weight: bold"
              >mystery shopping</span
            >. Mystery shoppers are used by a lot of companies to obtain
            feedback on the customer service a business provides by following a
            set script. Some restaurants use this type of market research to
            monitor how their staff deal with customers who require a refund
            after buying a certain meal. Other shops may use mystery shoppers to
            assess what customers are told when they enquire about a certain
            service or product.
          </p>
          <p>
            Another popular market research opportunity is to give your feedback
            to companies by <span style="font-weight: bold"
              >completing online questionnaires</span
            > on different subjects.
          </p>
          <p>
            Other companies pay people to <span style="font-weight: bold"
              >test their products</span
            > and then provide a review, while some want people to become
            members of Focus Groups and then provide feedback regarding certain
            products or services that have been received.
          </p>
        </template>
      </Content>
      <!-- <Content
      type="left"
      title="We have other platforms to recommend you."
      >
        
      </Content> -->
      <div class="fotters">
        <div class="title">We have other platforms to recommend you.</div>
        <div class="imgcs">
          <a
            href="https://thepanelstation.go2cloud.org/aff_c?offer_id=815&aff_id=4773&aff_sub=10005"
            target="_blank"
          >
            <img src="../../static/title1.png"
          /></a>
          <a href="https://tgmresearch.com" target="_blank">
            <img src="../../static/title2.png"
          /></a>
          <a href="https://bmv.biz/?a=6478&c=1&s1=hm05" target="_blank"
            ><img src="../../static/title3.png"
          /></a>
          <a href="https://www.opinionworld.com.au/signup" target="_blank">
            <img src="../../static/title4.png"
          /></a>
        </div>
      </div>
    </div>
  </BasePage>
</template>

<script setup>
import { reactive } from "vue";
import BasePage from "@/components/BasePage/index.vue";
import Banner from "./components/Banner/index.vue";
import Form from "./components/Form/index.vue";
import Content from "./components/Content/index.vue";
import { team_icon } from "@/imgData";
const data = reactive({});
</script>

<style scoped lang="less">
@import "./index.less";
</style>