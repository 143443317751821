<template>
  <div class="basePage_wrap">
    <Header />
    <slot />
    <Footer />
  </div>
</template>

<script setup>
import { reactive } from "vue";
import Header from "./components/Header/index.vue";
import Footer from "./components/Footer/index.vue";
const data = reactive({});
</script>

<style scoped lang="less">
@import "./index.less";
</style>