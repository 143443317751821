<template>
  <div class="form_box">
    <div class="form_wrap">
      <el-card body-style="padding:40px 40px 20px 20px">
        <el-form label-position="right" label-width="150px">
          <el-form-item label="FIRST NAME:">
            <el-input v-model="form.FirstName"></el-input>
          </el-form-item>
          <el-form-item label="LAST NAME:">
            <el-input v-model="form.LastName"></el-input>
          </el-form-item>
          <el-form-item label="EMAIL:">
            <el-input v-model="form.email"></el-input>
          </el-form-item>
          <el-form-item label="POST CODE:">
            <el-input v-model="form.postcode"></el-input>
          </el-form-item>
          <el-form-item label="GENDER:">
            <el-select v-model="form.gender">
              <el-option
                v-for="item in [
                  {
                    label: 'MAN',
                    value: '1',
                  },
                  {
                    label: 'WOMAN',
                    value: '2',
                  },
                  {
                    label: 'SECRET',
                    value: '3',
                  },
                ]"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="DATE OF BIRTH:">
            <el-date-picker v-model="form.BirthDate" type="date" />
            <!-- <el-input v-model="form.Phone"></el-input> -->
          </el-form-item>
          <div class="agreement_wrap">
            <el-checkbox
              :checked="isRead"
              @change="isRead = !isRead"
            ></el-checkbox>
            I have read, understand and agree to the
            <span
              style="color: #6b84ff; cursor: pointer"
              @click="toHref('privacy')"
            >
              《Privacy Policy》
            </span>
            and
            <span
              style="color: #6b84ff; cursor: pointer"
              @click="toHref('user')"
            >
              《User Agreement》
            </span>
          </div>
          <div style="text-align: center">
            <el-button
              color="rgb(107, 132, 255)"
              style="color: #fff"
              @click="submitInfo"
              >Sign up and Earn</el-button
            >
          </div>
        </el-form>
      </el-card>
    </div>
    <div class="check_wrap">
      <div v-for="(item, index) in checkList" :key="index">
        <el-checkbox
          :checked="activeIndex == index"
          size="large"
          @change="checkIndex(index)"
        />
        <span class="check_item_title"
          >{{ item.title }} <img :src="item.logo" alt=""
        /></span>
        <p>{{ item.content }}</p>
      </div>
    </div>
  </div>
</template>

<script setup>
import { reactive, computed, ref } from "vue";
import { useRouter } from "vue-router";
import { tiLogo, voLogo, yougoLogo } from "@/imgData";
import { ElMessage } from "element-plus";

const router = useRouter();
const isRead = ref(false);
const checkIndex = (index) => {
  activeIndex.value = index;
};
const toHref = (value) => {
  if (value == "privacy") {
    router.push({
      path: "/privacy",
    });
  }
  if (value == "user") {
    router.push({
      path: "/user",
    });
  }
};
const submitInfo = () => {
  console.log(isRead, "isRead", isRead.value);
  if (!isRead.value) {
    ElMessage({
      message: "Please agree to the Agreement",
      type: "error",
    });
    return;
  }
  if (
    !form.BirthDate ||
    !form.email ||
    !form.FirstName ||
    !form.gender ||
    !form.LastName ||
    !form.postcode
  ) {
    ElMessage({
      message: "Please enter the required information",
      type: "error",
    });
    return;
  }
  ElMessage({
    message:
      "Great news! We have matched your profile to the selection of opportunities and you will receive the activation email soon. Get started and earning money today!",
    type: "success",
  });
};
const activeIndex = ref(-1);
const checkList = computed(() => {
  return [
    {
      title: "YouGov",
      content:
        "Join today! Get 150 points bonus! You will receive cash by completing surveys!",
      logo: yougoLogo,
    },
    {
      title: "Toluna",
      content:
        "Get rewarded for giving your opinion that matter to you. Come join and collect your rewards!",
      logo: tiLogo,
    },
    {
      title: "Valued Opinions",
      content:
        "Join free today and start earning your rewards by participating in paid surveys ",
      logo: voLogo,
    },
  ];
});

const form = reactive({
  FirstName: "",
  LastName: "",
  email: "",
  postcode: "",
  gender: "",
  BirthDate: "",
});
</script>

<style scoped lang="less">
@import "./index.less";
</style>