const logo = require('@/static/logo.png')
const banner_bg = require('@/static/bg-banner.png')
const wxQr = require('@/static/ewm.jpg')
const banner_img = require('@/static/hero-shape.png')
const money_icon = require('@/static/startearning-icon.png')
const team_icon = require('@/static/signup-icon.png')
const tiLogo = require('@/static/tiLogo.png')
const voLogo = require('@/static/voLogo.png')
const yougoLogo = require('@/static/yougoLogo.png')

export {
  logo,
  banner_bg,
  wxQr,
  banner_img,
  money_icon,
  team_icon,
  tiLogo,
  voLogo,
  yougoLogo
}
