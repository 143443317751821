<template>
  <div class="title">{{ props.title }}</div>
  <div class="content_wrap" v-if="props.type == 'right'">
    <div class="content">
      <h2>{{ props.title2 }}</h2>
      <slot name="content" />
    </div>
    <div class="img_box">
      <img :src="props.img" alt="" />
    </div>
  </div>
  <div class="content_wrap" v-if="props.type == 'left'">
    <div class="img_box">
      <img :src="props.img" alt="" />
    </div>
    <div class="content">
      <h2>{{ props.title2 }}</h2>
      <slot name="content" />
    </div>
  </div>
</template>

<script setup>
import { onMounted, reactive, ref, defineProps } from "vue";
import { money_icon } from "@/imgData";
const props = defineProps({
  title: {
    type: String,
    default: () => "WAHT CAN YOU DO IN OUR TEAM?",
  },
  title2: {
    type: String,
    default: () => "You can earn money for giving your opinion!",
  },
  img: {
    default: () => money_icon,
  },
  type: {
    type: String,
    default: () => "right",
  },
});
</script>

<style scoped lang="less">
@import "./index.less";
</style>