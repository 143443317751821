<template>
  <div class="banner_box">
    <div class="banner_text_wrap">
      <p>
        Welcome to Do Survey, a community where we share the most reliable paid
        surveys. Do Survey work only with trusted market research companies.
      </p>
    </div>
    <div class="banner_img_wrap">
      <img :src="banner_img" />
    </div>
  </div>
</template>

<script setup>
import { reactive } from "vue";
import { banner_img } from "@/imgData";

const data = reactive({});
</script>

<style scoped lang="less">
@import "./index.less";
</style>