<template>
  <div class="header_wrap">
    <div class="header_logo">
      <img :src="logo" alt="" style="max-width: 200px" />
    </div>
    <div class="header_link">
      <div
        v-for="(item, index) in linkList"
        :key="index"
        @click="toHref(item.path)"
      >
        {{ item.title }}
      </div>
    </div>
  </div>
</template>

<script setup>
import { reactive } from "vue";
import { logo } from "@/imgData";
import { useRouter } from "vue-router";
const router = useRouter();
const data = reactive({});
const linkList = [
  {
    title: "HOME",
    path: "/index",
  },
  {
    title: "USER AGREEMENT",
    path: "/user",
  },
  {
    title: "PRIVACY AGREEMENT",
    path: "/privacy",
  },
];
const toHref = (path) => {
  router.push({
    path,
  });
};
</script>

<style scoped lang="less">
@import "./index.less";
</style>