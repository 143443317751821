<template>
  <!-- <div class="footer_wrap">
    <div class="footer_content_wrap">
      <div class="footer_content_left">
        <img :src="logo" alt="" style="max-width: 200px" />
        <div>
          <p style="white-space: nowrap">
            {{ isCn ? "客服咨询" : "Customer Service Consultation" }}
          </p>
          <p>15910899775</p>
          <p>{{ isCn ? "商务咨询" : "Business Consulting" }}</p>
          <p>15910899775</p>
          <p>{{ isCn ? "邮箱" : "Email" }}</p>
          <p>shiyu@yishouwl.com</p>
        </div>
      </div>
      <div class="footer_content_right">
        <img :src="wxQr" />
        <p>{{ isCn ? "微信公众号" : "Official Accounts" }}</p>
      </div>
    </div>
  </div> -->
  <div class="footer_bottom">
    <p>
      If you have any questions about paid surveys (or anything else for that
      matter) please feel free to contact us at  gushaowei@baitiangong.cn
    </p>
    <div class="footer_bottom_wrap">
      <p style="margin-right: 30px;">Cpoyright 2022 All Rights ZY Adstech</p>
      <p>
        <span style="cursor: pointer" @click="toHref('privacy')">
          Privacy Policy </span
        >|
        <span style="cursor: pointer" @click="toHref('user')">
          User Agreement
        </span>
      </p>
    </div>
  </div>
</template>

<script setup>
import { reactive, computed } from "vue";
import { wxQr, logo } from "@/imgData";
import { useRouter } from "vue-router";
const router = useRouter();
const isCn = computed(() => false);
const toHref = (value) => {
  if (value == "privacy") {
    router.push({
      path: "/privacy",
    });
  }
  if (value == "user") {
    router.push({
      path: "/user",
    });
  }
};
</script>

<style scope lang="less">
@import "./index.less";
</style>
